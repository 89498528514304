import React from 'react';

import { Content, Line, Over } from './styles';

const OverLine = () => {
  return (
    <Content>
      <Line />
      <Over>
        <p>Over</p>
      </Over>
    </Content>
  );
};

export default OverLine;
