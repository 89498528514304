import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.large} 0;
  gap: ${({ theme }) => theme.spacing.large};

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.large} 0;
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.colors.purpleLight};
    align-items: flex-start;
  }
`;

export const Icon = styled.img`
  width: ${({ theme }) => `${theme.newTheme.spacing(34)}`};
  height:  ${({ theme }) => `${theme.newTheme.spacing(34)}`};

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    width: ${({ theme }) => `${theme.newTheme.spacing(27)}`};
    height: ${({ theme }) => `${theme.newTheme.spacing(27)}`};
  }
`;

export const Content = styled.div`
  border-left: 2px solid ${({ theme }) => theme.colors.purpleLight};
  padding-left: ${({ theme }) => theme.spacing.large};
  display: flex;
  gap: ${({ theme }) => theme.spacing.medium};

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    border-left: 0;
    padding: 0;
  }
`;

export const Title = styled.h6`
  margin: 0;
  font-size: ${({ theme }) => theme.fonts.sizes.heading25};
  color: ${({ theme }) => theme.colors.purpleLight};
  font-weight: ${({ theme }) => theme.fonts.weights.boldCondensed};

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.sizes.primary};
  }
`;

export const Description = styled.p`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fonts.sizes.secondary20};
  color: ${({ theme }) => theme.fonts.colors.secondary};
  font-weight: ${({ theme }) => theme.fonts.weights.normal};
  line-height: ${({ theme }) => theme.lineHeight.secondary};
  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.sizes.small};
  }
`;
