import React from 'react';

import theme from 'styles/theme';
import TeamAug1 from 'static/images/icons/1-team-augmentation.svg';
import TeamAug2 from 'static/images/icons/2-team-augmentation.svg';
import TeamAug3 from 'static/images/icons/3-team-augmentation.svg';
import TeamAug4 from 'static/images/icons/4-team-augmentation.svg';

const teamAugmentationListSection = {
  title: (
    <>
      From 0 to <span>Kick-off</span>
    </>
  ),

  description: (
    <>
      <span>You're 4 steps away from leveling up your team.</span>
      From first contact to ramp up, here's what to expect:
    </>
  ),
  buttonText: "Let's get started",
  color: theme.colors.purpleLight,
  list: [
    {
      image: TeamAug1,
      alt: 'number 1',
      title: 'Meet',
      description:
        "Our collaboration starts with a quick chat to understand your needs, and challenges. We'll get to know you to make sure we're the best fit.",
    },
    {
      image: TeamAug2,
      alt: 'number 2',
      title: 'Assess',
      description:
        "Next, we'll get under the hood to understand your technical architecture. Analyze your code and scope out your project.",
    },
    {
      image: TeamAug3,
      alt: 'number 3',
      title: 'Plan',
      description:
        "Finally, we'll prepare a detailed project plan including our technical findings. And hand-pick your dream team.",
    },
    {
      image: TeamAug4,
      alt: 'number 4',
      title: 'Kick-off',
      description:
        "Let's get this show on the road! We set up our kick-off meetings, you meet the team, and we start ramping up. The rest is history.",
      isLast: true,
    },
  ],
};

export default teamAugmentationListSection;
