import styled from 'styled-components';

export const Card = styled.li`
  box-shadow: ${({ theme }) => theme.shadows.buttonPrimary};
  border-radius: ${({ theme }) => theme.radius.small};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.large};
  }
`;

export const LineContainer = styled.div`
  position: absolute;
  display: none;

  hr {
    width: 100vw;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    display: inherit;
  }
`;

export const TextContainer = styled.div`
  position: relative;
  width: 100%;

  h6 {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ArrowContainer = styled.span`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.buttonPrimary};
  border-radius: ${({ theme }) => theme.radius.full};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;

  svg {
    width: ${({ theme }) => theme.spacing.medium};
    height: ${({ theme }) => theme.spacing.medium};
    margin: ${({ theme }) => theme.spacing.small};
    transform: translateY(1.5px);

    @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
      width: ${({ theme }) => theme.spacing.smallXL};
      height: ${({ theme }) => theme.spacing.smallXL};
      margin: ${({ theme }) => theme.spacing.smallest};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.desktop}) {
    display: inherit;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const TextOver = styled.h6`
  font-size: ${({ theme }) => theme.spacing.spacing25};
  margin: 0;
  width: 100%;
  text-align: center;
  line-height: ${({ theme }) => theme.spacing.spacing25};
  padding: ${({ theme }) => theme.spacing.large};
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 700;

  span {
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 700;
    display: block;

    @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
      display: contents;
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    font-size: ${({ theme }) => theme.spacing.spacing20};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.spacing.spacing14};
    line-height: ${({ theme }) => theme.spacing.spacing25};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.largest};
    font-size: ${({ theme }) => theme.spacing.spacing23};
    line-height: ${({ theme }) => theme.spacing.spacing30};
  }
`;

export const TextBelow = styled.p`
  ${({ theme }) => `padding: ${theme.spacing.largest} ${theme.spacing.large}`};
  font-size: ${({ theme }) => theme.spacing.spacing20};
  line-height: ${({ theme }) => theme.spacing.spacing25};
  color: ${({ theme }) => theme.colors.primaryText};
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.primaryText};
    @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
      display: contents;
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    font-size: ${({ theme }) => theme.spacing.spacing14};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.spacing.smallXL};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.spacing.spacing20};
    ${({ theme }) => `padding: ${theme.spacing.longSM} ${theme.spacing.spacing20}`};
  }
`;
