import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Container, Content, Title, Description } from './styles';

const IconAndInformation = ({ icon, title, description, alt }) => {
  return (
    <Container>
      <Icon src={icon} alt={alt} />
      <Content>
        <div>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </div>
      </Content>
    </Container>
  );
};

IconAndInformation.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

IconAndInformation.defaultProps = {
  alt: '',
};

export default IconAndInformation;
