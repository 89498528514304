import React from 'react';

import { Container, Row } from 'react-grid-system';
import valuesList from 'domain/agileValuesList';
import AgileValueItem from './agile-value-item';
import OverLine from './over-line';
import { ContainerAgile, Content, List, ContainerOverline } from './styles';

const AgileValues = () => {
  return (
    <Content>
      <ContainerOverline>
        <OverLine />
      </ContainerOverline>
      <Container component={ContainerAgile}>
        <List>
          <Row>
            {valuesList.map(value => (
              <AgileValueItem
                key={value.id}
                titleOver={value.titleOver}
                titleBelow={value.titleBelow}
              />
            ))}
          </Row>
        </List>
      </Container>
    </Content>
  );
};

export default AgileValues;
