import React, { useRef } from 'react';
import MetaTags from 'core/meta-tags/index';
import { graphql } from 'gatsby';
import Link from 'atomic-design/atoms/link';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { renderLineBreak } from 'common/string-helpers';
import Clock from 'static/images/icons/clock.svg';
import Lens from 'static/images/icons/search.svg';
import theme from 'styles/theme';
import Layout from 'core/layout';
import PageHeader from 'atomic-design/atoms/page-header';
import PageSection from 'atomic-design/atoms/page-section';
import Button from 'atomic-design/atoms/button';
import Testimonial from 'atomic-design/molecules/testimonial';
import IconAndInformation from 'atomic-design/molecules/icon-and-information';
import Clients from 'atomic-design/organisms/clients';
import Tshaped from 'atomic-design/organisms/t-shaped';
import BuiltSection from 'atomic-design/organisms/built-section';
import { teamAugmentationList } from 'domain/TShapedLists';
import TshapedImage from 'static/images/icons/T-shaped.svg';
import CallToActionDiagonal from 'atomic-design/organisms/call-to-action-diagonal';
import ListCircleImages from 'atomic-design/organisms/list-circle-images';
import teamAugmentationListSection from 'domain/teamAugmentationListSection';
import MainContainer from 'atomic-design/atoms/main-container';
import AgileValues from 'atomic-design/molecules/agile-values';
import AgileInfoItem from 'atomic-design/molecules/agile-info-item';
import ListDivider from 'atomic-design/atoms/list-divider';
import Overrun from 'atomic-design/organisms/overrun-section';
import Image from 'core/image';

import {
  VisionText,
  TestimonialBox,
  DiagonalBackground,
  ContainerStyled,
  ItemContainer,
  ClientTitle,
  ClientsContainer,
  CallToActionTshaped,
  CallToActionContent,
  TshapedContainer,
  TestimonialContainer,
  AgileValuesTitle,
  PageHeaderContainer,
  ImgContainer,
  VisionSectionContainer,
} from 'styles/pages/team-augmentation-styles';

export const TeamAugmentationQuery = graphql`
  query TeamAugmentationQuery {
    allContentfulPage(filter: { url: { eq: "services/team-augmentation" } }) {
      edges {
        node {
          headerTitle {
            json
          }
          headerSubtitle
          headerDescription {
            headerDescription
          }
          metatagImage {
            file {
              url
            }
          }
          metatagTitle
          metatagDescription
        }
      }
    }
    allContentfulTestimonialFeatured(
      filter: { pageId: { eq: "teamAugmentation" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          title {
            json
          }
          description {
            description
          }
          author {
            image {
              contentful_id
            }
            name
            role
          }
        }
      }
    }
    allContentfulClient(
      filter: { pagesIn: { elemMatch: { url: { eq: "services/team-augmentation" } } } }
      sort: { fields: order }
    ) {
      edges {
        node {
          companyName
          website
          logo {
            contentful_id
            title
          }
          pagesIn {
            url
          }
          order
        }
      }
    }
    allContentfulCaseStudy(
      filter: { pageId: { eq: "teamAugmentation" } }
      sort: { fields: publishedDate, order: DESC }
      limit: 2
    ) {
      edges {
        node {
          title
          slug
          LimitedAbstract
          thumbnail {
            contentful_id
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`;

const TeamAugmentation = ({ data, location }) => {
  const clients = data.allContentfulClient.edges;
  const pageHeaderData = data.allContentfulPage.edges[0].node;
  const testimonialFeatured1 = data.allContentfulTestimonialFeatured.edges[0].node;
  const testimonialFeatured2 = data.allContentfulTestimonialFeatured.edges[1].node;
  const caseStudiesData = data.allContentfulCaseStudy.edges;
  const metatagImage = data.allContentfulPage.edges[0].node.metatagImage.file.url;
  const metatagDescription = data.allContentfulPage.edges[0].node.metatagDescription;
  const metatagTitle = data.allContentfulPage.edges[0].node.metatagTitle;

  const callToActionRef = useRef();

  const handleAnchor = () => {
    const headerOffset = 85;
    const elementPosition = callToActionRef.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <Layout path={location.pathname} bodyType="xWide">
      <MetaTags
        title={metatagTitle}
        description={metatagDescription}
        charSet="utf-8"
        image={metatagImage}
        viewport="width=device-width, initial-scale=1"
        type="website"
      />

      <PageSection>
        <PageHeaderContainer>
          <PageHeader
            title={pageHeaderData.headerSubtitle}
            subTitle={documentToReactComponents(pageHeaderData.headerTitle.json)}
            description={renderLineBreak(pageHeaderData.headerDescription.headerDescription)}
            color={theme.colors.purpleLight}
          />
        </PageHeaderContainer>
        <Button variant="contained" onClick={handleAnchor}>
          Level up my team
        </Button>
      </PageSection>

      <Overrun
        padding={`${theme.newTheme.spacing(40)} 0 ${theme.newTheme.spacing(30)} 0`}
        background={theme.newTheme.color.service.teamAugmentation.background}
        margin={`${theme.newTheme.spacing(12)} 0`}
        mobilePadding={`${theme.newTheme.spacing(30)} 0 ${theme.newTheme.spacing(10)} 0`}
      >
        Overrun with development tasks and can't see the end in sight?
        <span> Unclog the bottleneck with experienced engineers from our crew</span>
      </Overrun>

      <PageSection>
        <TestimonialContainer>
          <ListDivider />
          <TestimonialBox variant="borderColor">
            <Testimonial
              title={documentToReactComponents(testimonialFeatured1.title.json)}
              description={testimonialFeatured1.description.description}
              image={testimonialFeatured1.author.image.contentful_id}
              authorName={testimonialFeatured1.author.name}
              authorRol={testimonialFeatured1.author.role}
              alt={`${testimonialFeatured1.author.name} - ${testimonialFeatured1.author.rol}`}
              textAlign="center"
            />
          </TestimonialBox>
          <ListDivider />
        </TestimonialContainer>
      </PageSection>

      <PageSection margin={`${theme.spacing.longest} 0`}>
        <DiagonalBackground />
        <ContainerStyled>
          <ItemContainer>
            <IconAndInformation
              icon={Clock}
              title="The same great work, done faster"
              description="When you're struggling to get things shipped fast enough, just getting extra hands on deck isn't enough. We support you with expert planning and technical processes, so you complete your milestones faster without sacrificing quality."
            />
          </ItemContainer>
          <ItemContainer>
            <IconAndInformation
              icon={Lens}
              title="Stop looking for the needle in the haystack"
              description="Attracting the right people is really hard (and it takes forever). So when hiring permanent staff isn't practical, why not use our proven talent? We hand-pick experts from our diverse team to work hand-in-hand with your engineers, or partner with your product managers."
            />
          </ItemContainer>
        </ContainerStyled>
      </PageSection>

      <PageSection margin={`${theme.spacing.largeXL} 0`}>
        <AgileInfoItem
          title="No more fake agile"
          description={[
            'As true believers in the',
            <span>agile software manifesto</span>,
            ', we take iterative development seriously. That means retros, daily huddles and real collaboration. And not just for the sake of it. Our processes help us work more effectively to deliver you a stronger product.',
          ]}
        />
        <AgileValuesTitle>
          The 4<span> Agile Values</span>
        </AgileValuesTitle>
        <AgileValues />
        <AgileInfoItem
          title="Roll with the punches"
          description={[
            'By working with',
            <span>Scrum </span>,
            'and',
            <span>Kanban </span>,
            "frameworks, we're flexible and ready for whatever unexpected surprises are thrown our way. So you can ship features regularly, without interruption.",
          ]}
          invertOrder={true}
        />
      </PageSection>

      <PageSection background={theme.colors.purpleBackground}>
        <TestimonialBox variant="whiteBox">
          <Testimonial
            title={documentToReactComponents(testimonialFeatured2.title.json)}
            description={testimonialFeatured2.description.description}
            image={testimonialFeatured2.author.image.contentful_id}
            authorName={testimonialFeatured2.author.name}
            authorRol={testimonialFeatured2.author.role}
            alt={`${testimonialFeatured2.author.name} - ${testimonialFeatured2.author.rol}`}
            textAlign="center"
          />
        </TestimonialBox>
      </PageSection>

      <PageSection padding={`${theme.spacing.long} 0`} margin={`${theme.spacing.largest} 0 0 0`}>
        <ClientTitle>
          Powering up teams <span>like yours</span>
        </ClientTitle>
        <ClientsContainer>
          <Clients clients={clients} />
        </ClientsContainer>
      </PageSection>

      <VisionSectionContainer>
        <ImgContainer>
          <Image alt="an Image" partialPathName="team-augmentation-5000" width="100%" fluid />
        </ImgContainer>
        <PageSection padding={`${theme.spacing.largest} 0`}>
          <VisionText>
            <span>You're a small team with a big vision.</span>
            Imagine what you could do with NaNLABS on your side.
          </VisionText>
        </PageSection>
      </VisionSectionContainer>

      <PageSection margin={`${theme.spacing.largest} 0 ${theme.spacing.spacing120} 0`}>
        <ListCircleImages
          title={teamAugmentationListSection.title}
          description={teamAugmentationListSection.description}
          buttonText={teamAugmentationListSection.buttonText}
          colorList={teamAugmentationListSection.color}
          list={teamAugmentationListSection.list}
        />
      </PageSection>

      <PageSection
        background={theme.colors.purpleBackground}
        padding={`${theme.spacing.longSM} 0`}
        containerSize="fluid"
      >
        <MainContainer>
          <Tshaped cardArray={teamAugmentationList} />
        </MainContainer>
        <CallToActionTshaped>
          <CallToActionContent>
            <MainContainer>
              <h6>Get to know us better?</h6>
              <Link to="/about-us" rel="noreferrer">
                <Button variant="contained">Meet the team</Button>
              </Link>
            </MainContainer>
          </CallToActionContent>
          <TshapedContainer>
            <img src={TshapedImage} alt="T-shaped" />
          </TshapedContainer>
        </CallToActionTshaped>
      </PageSection>

      <PageSection padding={`${theme.newTheme.spacing(25)} 0`}>
        <BuiltSection caseStudies={caseStudiesData} />
      </PageSection>

      <div ref={callToActionRef} />
      <CallToActionDiagonal
        background={theme.gradients.heading}
        padding={`${theme.spacing.long} 0`}
        title="Ready to expand your team?"
        description="Whether it's for a question or a quote, we'd love to hear from you"
        buttonText="Let's talk"
      />
    </Layout>
  );
};

TeamAugmentation.propTypes = {};

TeamAugmentation.defaultProps = {};

export default TeamAugmentation;
