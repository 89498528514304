import React from 'react';

const valuesList = [
  {
    id: 0,
    titleOver: ['Individuals ', <span>& iterations</span>],
    titleBelow: ['Process ', <span>& tools</span>],
  },
  {
    id: 1,
    titleOver: ['Working ', <span>software</span>],
    titleBelow: ['Comprehensive ', <span>documentation</span>],
  },
  {
    id: 2,
    titleOver: ['Customer ', <span>collaboration</span>],
    titleBelow: ['Contract ', <span>negotiation</span>],
  },
  {
    id: 3,
    titleOver: ['Responding ', <span>to change</span>],
    titleBelow: ['Following ', <span>a plan</span>],
  },
];

export default valuesList;
