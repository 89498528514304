import React from 'react';

import { Col } from 'react-grid-system';
import theme from 'styles/theme';
import { ArrowDown } from 'ui/svg';
import OverLine from '../over-line';
import { Card, TextBelow, TextOver, TextContainer, LineContainer, ArrowContainer } from './styles';

const AgileValueItem = ({ titleOver, titleBelow }) => {
  return (
    <Col xs={12} sm={6} md={3} lg={3}>
      <Card>
        <LineContainer>
          <OverLine />
        </LineContainer>
        <ArrowContainer>
          <ArrowDown color={theme.colors.secondary} aria-label="Over" />
        </ArrowContainer>
        <TextContainer>
          <TextOver>{titleOver}</TextOver>
          <TextBelow>{titleBelow}</TextBelow>
        </TextContainer>
      </Card>
    </Col>
  );
};

export default AgileValueItem;
