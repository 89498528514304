import styled from 'styled-components';

export const ContainerAgile = styled.div`
  margin: 0 !important;
  max-width: 100% !important;
  padding: 0 !important;
  width: 100%;
`;

export const ContainerOverline = styled.div`
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.longest};

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    margin-bottom: ${({ theme }) => theme.spacing.largest};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: ${({ theme }) => theme.spacing.large};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
`;

export const List = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
`;
