import styled, { css } from 'styled-components';

const variantStyles = (theme, variant) =>
  ({
    whiteBox: css`
      border-radius: ${theme.radius.secondary};
      box-shadow: ${theme.shadows.buttonPrimary};
      background-color: ${theme.colors.white};
    `,
    borderColor: css`
      @media only screen and (${theme.breakpoints.mobile}) {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    `,
  }[variant]);

export const PageHeaderContainer = styled.section`
  padding-bottom: ${({ theme }) => theme.newTheme.spacing(12)};

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding-bottom: ${({ theme }) => theme.newTheme.spacing(6)};
  }
`;

export const VisionText = styled.h4`
  width: 85%;
  font-size: ${({ theme }) => `${theme.fonts.sizes.headings.heading3}`};
  font-weight: ${({ theme }) => `${theme.fonts.weights.boldCondensed}`};
  color: ${({ theme }) => `${theme.colors.white}`};

  span {
    display: block;
    font-weight: ${({ theme }) => `${theme.fonts.weights.boldCondensed}`};
    color: ${({ theme }) => `${theme.colors.white}`};

    @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
      font-size: ${({ theme }) => `${theme.fonts.sizes.headings.heading5}`};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    width: 90%;
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => `${theme.fonts.sizes.headings.heading5}`};
    span {
      line-height: ${({ theme }) => `${theme.spacing.large}`};
    }
    line-height: ${({ theme }) => `${theme.spacing.large}`};
  }
`;
export const ImgContainer = styled.div`
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    width: 100%;
    height: 100%;
  }
`;

export const VisionSectionContainer = styled.div`
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.65);
`;

export const DiagonalBackground = styled.div`
  left: 0;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 35em;
  background: ${({ theme }) => theme.colors.purpleBackground};
  transform: skewY(7deg);

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    height: 30em;
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    height: 50em;
  }
`;

export const ContainerStyled = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.largest} 0`};
  z-index: 9;
  position: relative;
  display: flex;
  flex-direction: column;

  div:nth-child(2) {
    margin-left: auto;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    div:nth-child(2) {
      margin-left: 0;
    }
  }
`;

export const BoxesContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.largest};
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.spacing18};
  }
`;

export const TestimonialBox = styled.div`
  ${({ theme }) => `padding: ${theme.spacing.longSM} ${theme.spacing.long}`};
  max-width: 48.5em;
  margin: ${({ theme }) => theme.spacing.long} auto;
  overflow: hidden;
  ${({ theme, variant }) => variantStyles(theme, variant)};

  h5 {
    font-weight: 600;
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};

    @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.fonts.sizes.heading23};
      position: relative;
      z-index: 9;

      b {
        display: block;
      }
    }
  }
  svg {
    opacity: 0.5;

    @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
      position: absolute;
      z-index: 0;
      transform: translateX(-100px) translateY(0);
    }
  }
  hr {
    background-color: ${({ theme }) => theme.fonts.colors.secondary};
  }
  q {
    font-size: ${({ theme }) => theme.fonts.sizes.medium};
    line-height: ${({ theme }) => theme.lineHeight.secondary};

    @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.fonts.sizes.primary};
    }
  }
  figcaption {
    span {
      color: ${({ theme }) => theme.colors.purpleLight};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    ${({ theme }) => `padding: ${theme.spacing.large} ${theme.spacing.large}`};
    margin: ${({ theme }) => theme.spacing.largest} auto;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
    ${({ theme }) => `padding: ${theme.spacing.large} ${theme.spacing.medium}`};
  }
`;

export const ItemContainer = styled.div`
  width: 70%;
  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const AgileValuesTitle = styled.h5`
  font-size: ${({ theme }) => theme.fonts.sizes.headings.heading4};
  color: ${({ theme }) => theme.colors.black};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.spacing20};
  font-weight: 600;

  width: 100%;
  text-align: center;

  span {
    background: ${({ theme }) => `${theme.gradients.background}`};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    font-size: ${({ theme }) => theme.spacing.largeXL};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    font-size: ${({ theme }) => theme.spacing.large};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.spacing.spacing25};
    text-align: start;
  }
`;

export const ClientTitle = styled.h5`
  font-size: ${({ theme }) => theme.fonts.sizes.headings.heading4};
  color: ${({ theme }) => theme.colors.black};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.largeXL};
  font-weight: ${({ theme }) => `${theme.fonts.weights.heavy}`};
  width: 100%;

  span {
    background: ${({ theme }) => `${theme.gradients.background}`};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: ${({ theme }) => `${theme.fonts.weights.heavy}`};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => `${theme.fonts.sizes.headings.heading5}`};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => `${theme.fonts.sizes.headings.heading5}`};

    span {
      display: block;
    }
  }
`;

export const ClientsContainer = styled.div`
  div {
    padding: 0;
  }
`;
export const ChecklistCol = styled.div`
  padding-left: 0 !important;
  padding-right: 50px !important;
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
`;
export const CallToActionTshaped = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.long} 0;

  h6 {
    margin: ${({ theme }) => theme.spacing.small} 0;
    font-size: ${({ theme }) => theme.fonts.sizes.heading30};
    font-weight: ${({ theme }) => theme.fonts.weights.bold};
    margin-bottom: ${({ theme }) => theme.spacing.large};

    @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.fonts.sizes.headings.heading5};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin: ${({ theme }) => theme.spacing.large} 0;
  }
`;

export const CallToActionContent = styled.div`
  z-index: 9;
  position: relative;
  width: 100%;
`;

export const TshapedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacing.medium};

  img {
    width: 100%;
  }
`;

export const TestimonialContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h5 b {
    @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
      display: inline;
    }
  }

  div:nth-child(2) {
    margin: 0 auto;
  }

  hr {
    height: 2px;
    max-width: 52em;
    background: ${({ theme }) => theme.colors.purpleBackground};
  }

  hr:nth-child(3) {
    margin-left: auto;
  }
`;
