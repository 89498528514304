import styled from 'styled-components';

export const Content = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
`;

export const Line = styled.hr`
  position: absolute;
  width: 100%;
  z-index: 1;
  border: medium none;
  border-top: 4px dashed ${({ theme }) => theme.colors.lightGrey};
`;

export const Over = styled.div`
  position: absolute;
  margin: 0;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 4px solid ${({ theme }) => theme.colors.lightGrey};
  z-index: 5;
  ${({ theme }) => `padding: ${theme.spacing.small} ${theme.spacing.large}`};
  border-radius: ${({ theme }) => theme.radius.small};

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.fonts.sizes.primary};
    color: ${({ theme }) => theme.colors.secondary};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    ${({ theme }) => `padding: ${theme.spacing.smallest} ${theme.spacing.medium}`};
    p {
      font-size: ${({ theme }) => theme.spacing.smallXL};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    ${({ theme }) => `padding: ${theme.spacing.smallest} ${theme.spacing.medium}`};
    p {
      font-size: ${({ theme }) => theme.spacing.medium};
    }
  }
`;
